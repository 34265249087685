 // export const baseUrl = "http://localhost:5004/api";
 

 export const baseUrl = "https://api.hand.do/api";

/* export const baseUrl = "http://192.168.10.40:5004/api"; */



/* export const baseUrl = "https://20ac-186-149-201-32.ngrok.io/api"; */


/* export const baseUrl = "http://localhost:5004/api"; */

