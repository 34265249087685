import React from 'react';

import {
    AiOutlineArrowUp,
    AiOutlineArrowDown,
    AiOutlineLine
} from 'react-icons/ai';

import { ApexChart } from '../../charts/ApexChart';
import { DashboardDocumentsTable } from '../../antTables/DashboardDocumentsTable';
import { DashboardGroupTable } from '../../antTables/DashboardGroupTable';
import { useSelector } from 'react-redux';

export const Dashboard = () => {
    const isSidebarActive = useSelector(state => state.ui.sidebar)

    return (
        <div className={isSidebarActive? '_OCS-dashboard-container':'_dashboard-container'}>
            <div className={isSidebarActive? '_OCS-overlay': '_overlay'}></div>
            <div className={isSidebarActive? '_OCS-CUDD-cards':'_CUDD-cards'}>
                <div className='_ant-row'>
                    <div className={isSidebarActive? '_OCS-customers-card':'_customers-card'}>
                        <h4 className='_card-header'>Clientes</h4>
                        <div className='_customer-card-data'>
                            <h1 className='_customer-data'>2456</h1>
                            <span className='_data-difference _data-difference-negative '>-11</span>
                            <AiOutlineArrowDown className='_customer-data-arrow' size={19} style={{color: '#ff6b72'}}/>
                        </div>
                        <div className='_customers-card-footer'>Comparado a al año pasado (2020)</div>
                    </div>
                    <div className={isSidebarActive? '_OCS-users-card':'_users-card'}>
                        <h4 className='_card-header'>Usuarios</h4>
                        <div className='_customer-card-data'>
                            <h1 className='_customer-data'>337</h1>
                            <span className='_data-difference _data-difference-neutral'>0</span>
                            <AiOutlineLine className='_customer-data-arrow' size={19} style={{color: '#008ffb'}}/>
                        </div>
                        <div className='_customers-card-footer'>Comparado a al año pasado (2020)</div>
                    </div>
                    <div className={isSidebarActive? '_OCS-devices-card':'_devices-card'}>
                        <h4 className='_card-header'>Dispositivos</h4>
                        <div className='_customer-card-data'>
                            <h1 className='_customer-data'>52006</h1>
                            <span className='_data-difference _data-difference-positive'>+300</span>
                            <AiOutlineArrowUp className='_customer-data-arrow' size={19} style={{color: '#04d182'}}/>
                        </div>
                        <div className='_customers-card-footer'>Comparado a al año pasado (2020)</div>
                    </div>
                </div>
                <div className={isSidebarActive? '_OCS-deliverys-graf':'_deliverys-graf'}>
                    <h4 className='_card-header _Dgraf-header'>Deliverys</h4>
                    <ApexChart />
                </div>
            </div>
            <div className={isSidebarActive? '_OCS-DG-cards':'_DG-cards'}>
                <div className={isSidebarActive? '_OCS-documents-card':'_documents-card'}>
                    <h4 className='_card-header _documents-header'>Documentos recientes</h4>
                    <DashboardDocumentsTable/>
                </div>
                <div className={isSidebarActive? '_OCS-groups-card':'_groups-card'}>
                    <h4 className='_card-header'>Grupos</h4>
                    <DashboardGroupTable/>
                </div>
            </div>
        </div>
    )
};