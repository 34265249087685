import React from 'react';
import { Line } from 'react-chartjs-2';

export const ApexChart = () => {

  const labels = [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003];
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Pendiente',
        fill: false,
        backgroundColor: '#008ffb',
        borderColor: '#008ffb',
        data: [30, 40, 45, 50, 49, 60, 70, 91, 20, 10, 40, 40, 80],
        lineTension: 0.3
      }, {
        label: 'Completados',
        fill: false,
        backgroundColor: '#04d182',
        borderColor: '#04d182',
        data: [20, 60, 62, 80, 70, 60, 40, 50, 60, 65, 75, 70, 86],
        lineTension: 0.3
      },
    ]
  };

  return (
    <Line
      data={data}
    />
  );
};