import React from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';

export const DashboardDocumentsTable = () => {
    const dataSource = [
        {
          key: '1',
          id: 1,
          type: 'Factura',
          customer: 'Test1',
        },
        {
          key: '2',
          id: 2,
          type: 'Contrato',
          customer: 'Test2',
        },
        {
          key: '3',
          id: 3,
          type: 'Conduce',
          customer: 'Test3',
        },
      ];
      
      const columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Tipo',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: 'Cliente',
          dataIndex: 'customer',
          key: 'customer',
        },
      ];

    return(
        <div>
            <Table dataSource={dataSource} columns={columns} />
        </div> 
    )
}