import React, { useState } from "react";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getEmptyFilters } from "../../helpers/getEmptyFilters";

export const FilterPanel = ({ setFilters, filterFields }) => {
  const filters = getEmptyFilters(filterFields);
  const percent = (1 /filterFields.length * 100)/2
  const [newFilters, setNewFilters] = useState({
    ...filters,
  });

  const onChange = (e) => {
    const { id, value } = e.target;
    setNewFilters({
      ...newFilters,
      [id]: value,
    });
  };

  const onFilter = () => {
    setFilters(newFilters);
  };

  const onClear = () => {
    setFilters({
      ...filters,
    });
    setNewFilters({
      ...filters,
    });
  };

  const FilterButton = ({ text, icon, onClick }) => {
    return (
      <Button
        icon={icon}
        type="primary"
        className="_btn-primary _ant-add-btn"
        style={{ margin: 5, width: 150 }}
        onClick={onClick}
      >
        {text}
      </Button>
    );
  };

  return (
    <>
      <div
        style={{
          marginBottom: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",

          flexWrap: "wrap",
        }}
      >
        {filterFields.map((c) => (
          <Input
            size="large"
            id={c.id}
            placeholder={c.name}
            style={{ margin: 5, width: `${percent}%` }}
            onChange={onChange}
            key={c.id}
            value={newFilters[c.id]}
          />
        ))}

        <FilterButton
          text="Filtrar"
          icon={<SearchOutlined />}
          onClick={onFilter}
        />
        <FilterButton
          text="Limpiar"
          icon={<SearchOutlined />}
          onClick={onClear}
        />
      </div>
    </>
  );
};
