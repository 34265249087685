import React, { useState } from 'react';
import { Button, DatePicker, Form, Input, Switch,Select} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { clearNCFRangeInfo, openScreen } from '../../actions/ui';
import { addOrUpdate } from '../../hooks/fetchHook';
import moment from 'moment';

export const EditNCFRangeScreen = () => {

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.ui.loading);
    const editingNCFRange = useSelector(state => state.ui.editingNCFRange);
    const isSidebarActive = useSelector(state => state.ui.sidebar);
    const realm = useSelector(state => state.auth.realm);
    const [formValues, setFormValues] = useState(editingNCFRange);
    const [form] = Form.useForm();

    const layout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 100 }
    };

    const handleInputchange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.id]: target.value,
            realmFk: realm
        })
    };

    const handleSave = async () => {
        try {
            await form.validateFields()
                .then(() => {
                    dispatch(addOrUpdate(formValues, 'ncfrange'))
                    dispatch(clearNCFRangeInfo())
                    form.resetFields()
                })
        }
        catch (err) { }
    }

    const handleDiscart = () => {
        form.resetFields()
        dispatch(clearNCFRangeInfo())
        dispatch(openScreen('uiOpenListNCFRange'))
    };

    const handdleStatus = (e) => {
        setFormValues({
            ...formValues,
            status: e
        })
    };

    const handleDateUntil = (date, dateString) => {
        setFormValues({
            ...formValues,
            validUntil: dateString,
        })
    }

    const handdleTypeNcf = (e) => {
        setFormValues({
            ...formValues,
            typeNcf: e
        }) 
    }

    return (
        <div>
            <div className={isSidebarActive ? '_OCS-overlay' : '_overlay'}></div>
            <div className='_header-container3'>
                <div className={isSidebarActive ? '_OCS-screen-header' : '_screen-header'}>
                    <h2>Editar NCF</h2>
                </div>
                <div className={isSidebarActive ? "_btn2-1_" : "_btn2_"}>
          <div className="_btns-container2">
            <Button className="_btn-primary _ant-add-btn " onClick={handleDiscart} type="primary">
              Volver
            </Button>
            </div>
          </div>
            </div>
            <div className={isSidebarActive ? '_OCS-ant-card-body _OCS-ant-card-form-group' : '_ant-card-body _ant-card-form-group'}>
                <div className='_ant-card-header'>Información Básica</div>
                <Form
                    form={form}
                    layout='vertical'
                    {...layout}
                    name="NCFRangeForm"
                    initialValues={{
                        id: [editingNCFRange.id],
                        typeNcf: [editingNCFRange.typeNcf],
                        startSequence: [editingNCFRange.startSequence],
                        finalSequence: [editingNCFRange.finalSequence],
                    }}
                >
                    <Form.Item
                        label="ID"
                        name="id"
                    >
                        <Input
                            disabled
                            id='id'
                            onChange={handleInputchange}
                            size='large'
                        />
                    </Form.Item>
                    <Form.Item
                                label="Tipo NCF"
                                name="typeNcf"
                            >
                                <Select 
                                    placeholder="Seleccionar tipo NCF" 
                                    // size='large' 
                                    onChange={handdleTypeNcf}
                                    allowClear
                                >
                                    <Select.Option value={1}>Crédito Fiscal</Select.Option>
                                    <Select.Option value={2}>Consumidor Final</Select.Option>
                                    <Select.Option value={14}>Régimen Especial</Select.Option>
                                    <Select.Option value={15}>Gubernamental</Select.Option>
                                </Select>
                            </Form.Item>

                    <Form.Item
                        label="Secuencia Inicial"
                        name="startSequence"
                        rules={[
                            {
                                required: true,
                                message: 'Favor ingresar secuencia válida',
                            },
                        ]}
                    >
                        <Input
                            id='startSequence'
                            onChange={handleInputchange}
                            size='large'
                        />
                    </Form.Item>

                    <Form.Item
                        label="Secuencia Final"
                        name="finalSequence"
                        rules={[
                            {
                                required: true,
                                message: 'Favor ingresar secuencia válida',
                            },
                        ]}
                    >
                        <Input
                            id='finalSequence'
                            onChange={handleInputchange}
                            size='large'
                        />
                    </Form.Item>

                    <Form.Item
                        label="Válido Hasta"
                        name="validUntil"
                        required
                    >
                        <DatePicker
                            onChange={handleDateUntil}
                            format="YYYY-MM-DD HH:mm:ss"
                            initialValues={ moment(editingNCFRange.validUntil) }
                            showTime
                        />
                    </Form.Item>
                </Form>
            </div>
            <div className={isSidebarActive ? '_OCS-ant-card-body _OCS-ant-card-form-group' : '_ant-card-body _ant-card-form-group'}>
                <div className='_ant-card-header'>Informacion del Recipiente</div>
                <div className='_ant-form-wrapper'>
                    <div>
                        <Form
                            form={form}
                            {...layout}
                            layout='vertical'
                            name="deviceInfoForm"
                            initialValues={{
                                taxPrefix: [editingNCFRange.taxPrefix]
                            }}
                        >
                            <Form.Item
                                label="Estatus"
                                name="status"
                                valuePropName="checked"
                            >
                                <Switch defaultChecked onChange={handdleStatus} checkedChildren="Activo" unCheckedChildren="Inactivo"></Switch>
                            </Form.Item>

                            <Form.Item
                                label="Prefijo de Impuesto"
                                name="taxPrefix"
                            >
                                <Input
                                    id='taxPrefix'
                                    onChange={handleInputchange}
                                    size='large'
                                />
                            </Form.Item>
                        </Form>
                    </div>

                    <div>
                        <Form
                            form={form}
                            {...layout}
                            layout='vertical'
                            name="NCFRangeForm"
                            initialValues={{
                                ordinal: [editingNCFRange.ordinal]
                            }}
                        >
                            <Form.Item
                                label="Ordinal"
                                name="ordinal"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Favor ingresar ordinal',
                                    },
                                ]}
                            >
                                <Input size='large' onChange={handleInputchange} id="ordinal" />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
            <div  className={
            isSidebarActive
              ? "_OCS-ant-card-body _OCS-ant-card-form-group _btn-save-container"
              : "_ant-card-body _ant-card-form-group _btn-save-container"
          }>
          <div >  
          <Button
              className="_btn-primary _ant-add-btn "
              loading={isLoading}
              onClick={handleSave}
              type="primary"
            >
              Guardar
            </Button>
            </div>
        </div>
        </div>
    )
};