import React from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';

import {
    AiOutlineArrowUp,
    AiOutlineArrowDown
} from 'react-icons/ai';

export const DashboardGroupTable = () => {
    const dataSource = [
        {
          key: '1',
          id: 1,
          miembros: 3098,
          dif: <div style={{color: '#008ffb'}}>100<AiOutlineArrowUp/></div>,
        },
        {
          key: '2',
          id: 2,
          miembros: 65043,
          dif: <div style={{color: '#ff6b72'}}>40<AiOutlineArrowDown/></div>,
        },
      ];
      
      const columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Miembros',
          dataIndex: 'miembros',
          key: 'miembros',
        },
        {
          title: 'Dif.',
          dataIndex: 'dif',
          key: 'dif',
        },
      ];

    return(
        <div>
            <Table dataSource={dataSource} columns={columns} />
        </div> 
    )
}