import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { clearNCFRangeGroupNCFRangeInfo, openScreen } from '../../actions/ui';
import { assocToGroup, getTableData } from '../../hooks/fetchHook';

export const AssociateNCFRangeGroupNCFRangeScreen = () => {

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const isLoading = useSelector(state => state.ui.loading);
    const editingNCFRangeGroupNCFRange = useSelector(state => state.ui.editingNCFRangeGroupNCFRange);
    const isSidebarActive = useSelector(state => state.ui.sidebar);
    const realm = useSelector(state => state.auth.realm);
    const [formValues, setFormValues] = useState(editingNCFRangeGroupNCFRange)
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(getTableData('ncfrange', realm))
            .then((response) => {
                setData(response)
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const layout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 100 }
    };
    
    const handdleInputChange = ({target}) => {
        setFormValues({
            ...formValues,
            [target.id]: target.value,
            realmFk: realm
        })
    };

    const handleSave = async () => {
        try{
            await form.validateFields()
            .then(() => {
                dispatch(assocToGroup(formValues, 'ncfrangegroupncfrange'))
                form.resetFields()
            })
        }
        catch(err) {}
    }
    
    const handdleDiscard = () => {
        form.resetFields()
        dispatch(clearNCFRangeGroupNCFRangeInfo())
        dispatch(openScreen('uiOpenListNCFRangeGroup'))
    };

    const handdleSelect = (e) => {
        setFormValues({
            ...formValues,
            entityId: e
        })
    };

    let children = [];
    const { Option } = Select;

    for (const prop of data) {
        children.push(<Option key={prop.id}>{prop.taxPrefix}</Option>)
    };

    return (
        <div>
            <div className={isSidebarActive? '_OCS-overlay': '_overlay'}></div>
            <div className='_header-container3'>
                <div className={isSidebarActive? '_OCS-screen-header':'_screen-header'}>
                    <h2>Agregar Rango NCF a Grupo</h2>
                </div>
                <div className={isSidebarActive ? "_btn2-1_" : "_btn2_"}>
          <div className="_btns-container2">
            <Button className="_btn-primary _ant-add-btn " onClick={handdleDiscard} type="primary">
              Volver
            </Button>
            </div>
          </div>
            </div>
            <div className={isSidebarActive? '_OCS-ant-card-body _OCS-ant-card-form-group':'_ant-card-body _ant-card-form-group'}>
                <div className='_ant-card-header'>Información Básica</div>
                <Form
                    form={form}
                    layout='vertical'
                    {...layout}
                    name="NCFRangeGroupNCFRangeGroup"
                    initialValues={{
                        groupFk: [editingNCFRangeGroupNCFRange.groupFk],
                        entityId: [editingNCFRangeGroupNCFRange.entityId],
                    }}
                >
                    <Form.Item
                        label="ID del Grupo"
                        name="groupFk"
                    >
                        <Input disabled size='large' onChange={handdleInputChange} id="groupFk"/>
                    </Form.Item>

                    <Form.Item
                        label="Rango NCF"
                        name="entityId"
                        rules={[
                            {
                                required: true,
                                message: 'Favor seleccionar Rango NCF',
                            }
                        ]}
                    >
                        <Select
                            onChange={handdleSelect}
                            allowClear
                            size='large'
                        >{children}</Select>
                    </Form.Item>
                </Form>
            </div>
            <div  className={
            isSidebarActive
              ? "_OCS-ant-card-body _OCS-ant-card-form-group _btn-save-container"
              : "_ant-card-body _ant-card-form-group _btn-save-container"
          }>
          <div >  
          <Button
              className="_btn-primary _ant-add-btn "
              loading={isLoading}
              onClick={handleSave}
              type="primary"
            >
              Guardar
            </Button>
            </div>
        </div>
        </div>
    )
};